<template>
  <div>
    <CLink
      v-if="isLoggedIn"
      :to="{name: 'contact_messages'}"
      translated
      :label="$t('views.action.back')"
      icon="arrow-left"
      class="btn-a-outline"
    />
    <CMessage :messages="message.base" />
    <div v-if="step==0">
      <CSelect
        v-if="!category_fixed"
        v-model="contact_message.category_list"
        label="contact_message.category_list"
        :options="category_list"
        :errors="message.category_list"
      />
      <div
        v-else
        class="tag-item-active"
      >{{ $t('activerecord.models.' + contact_message.category_list) }}</div>
      <CInputText
        v-model="contact_message.title"
        label="contact_message.title"
        :errors="message.title"
      />
      <CTextArea
        v-model="contact_message.body"
        label="contact_message.body"
        :rows="8"
        :errors="message.body"
      />
      <CInputText
        v-if="!isLoggedIn"
        v-model="contact_message.name"
        label="contact_message.name"
        :errors="message.name"
      />
      <CInputText
        v-if="!isLoggedIn"
        v-model="contact_message.email"
        label="contact_message.email"
        :errors="message.email"
      />
      <CButton
        label="send"
        :disabled="!(contact_message.category_list && contact_message.title && contact_message.body)"
        @click.prevent="submit"
      />
    </div>
    <div v-if="step==1">
      {{ $t('views.help.contact_message.create.success') }}
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "ContactMessage",
  data: function () {
    return {
      message: {},
      contact_message: {
        name: null,
        email: null,
        title: null,
        category_list: null,
        body: null
      },
      category_fixed: false,
      step: 0
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    category_list() {
      if(this.isLoggedIn) {
        return [
          { label: this.$t('activerecord.models.budget_sheet'), value: 'budget_sheet' },
          { label: this.$t('activerecord.models.other'), value: 'other' }
        ]
      } else {
        return [
          { label: this.$t('activerecord.models.user'), value: 'user' },
          { label: this.$t('activerecord.models.other'), value: 'other' }
        ]
      }
    }
  },
  created() {
    if(this.$route.query.c) {
      this.contact_message.category_list = this.$route.query.c;
      this.category_fixed = true
    }
  },
  methods: {
    submit() {
      var url = this.isLoggedIn ? 'current_user/contact_messages' : 'users/contact_messages'
      ApiRequestor.post(url, {contact_message: this.contact_message}).then(
        response => {
          this.message = {}
          if(this.isLoggedIn) {
            this.$router.push({name: 'contact_message_thread', params: {id: response.data.id} })
          } else {
            this.step++
          }
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>