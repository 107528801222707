<template>
  <template v-if="contactMessage.admin_id">
    <div>
      <mdicon name="face-agent" />
      <label class="label-a text-secondary">{{ contactMessage.created_at }}</label>
    </div>
    <div class="flex justify-start">
      <div class="card-a rounded-lg max-w-[85%] bg-green-100">
        <div
          v-if="contactMessage.title"
          class="font-bold whitespace-pre-line"
        >
          {{ contactMessage.title }}
        </div>
        <div
          class="whitespace-pre-line"
          v-html="contactMessage.body"
        />
      </div>
    </div>
  </template>
  <template v-else>
    <div class="text-right">
      <label class="label-a text-secondary">{{ contactMessage.created_at }}</label>
      <mdicon name="account" />
    </div>
    <div class="flex justify-end">
      <div class="card-a max-w-[85%] rounded-lg">
        <div
          v-if="contactMessage.title"
          class="font-bold"
        >
          {{ contactMessage.title }}
        </div>
        <div class="whitespace-pre-line">
          {{ contactMessage.body }}
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: 'CContactMessage',
  props: {
    contactMessage: Object
  },
  created() {
    if(this.contactMessage.user_unread) {
      ApiRequestor.post('current_user/contact_messages/' + this.contactMessage.id + '/read')
    }
  },
}
</script>