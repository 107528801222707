<template>
  <span class="cursor-pointer fi fi-vn" @click.prevent="changeLanguage('vi')" />
  <span class="cursor-pointer fi fi-jp" @click.prevent="changeLanguage('ja')" />
  <span class="cursor-pointer fi fi-us" @click.prevent="changeLanguage('en')" />
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "CLanguage",
  computed: {
    languages(){
      return [
        { value: 'vi', label: this.$t('views.language.vi'), icon: 'fi fi-vn' },
        { value: 'ja', label: this.$t('views.language.ja'), icon: 'fi fi-jp' },
        { value: 'en', label: this.$t('views.language.en'), icon: 'fi fi-us' },
      ]
    },
    availableLanguages() {
      return this.languages.map(obj => obj['value'])
    },
    queryLang() {
      return this.$route.query.lang
    }
  },
  watch: {
    queryLang(value, oldValue) {
      var currentQuery = { ...this.$route.query }
      delete currentQuery.lang
      this.$router.push({ query: currentQuery })
      if(this.$i18n.locale != value && this.availableLanguages.includes(value)) {
        this.$i18n.locale = value
        this.changeLanguage(value)
      }
    }
  },
  created() {
    this.$i18n.locale = ApiRequestor.getLanguage()
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      ApiRequestor.setLanguage(lang);
      window.location.reload()
    }
  }
}
</script>