<template>
  <div class="app">
    <div class="header flex justify-between">
      <div>
        <a href="" @click.prevent="goLanding"><img src='/logo.svg' alt="logo" /></a>
      </div>
      <div>
        <div class="text-right">
          <CLanguage />
        </div>
        <div class="flex" v-if="!isLoggedIn">
          <router-link :to="{name: 'login'}" class="text-blue-500 hover:text-primary-hover">{{ $t('views.route.login') }}</router-link>
          <router-link :to="{name: 'register'}" class="text-blue-500 hover:text-primary-hover text-white bg-primary px-2 ml-2 rounded-lg">{{ $t('views.route.register') }}</router-link>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="sidebar sm-except">
        <div class="menu mt-1">
          <div v-for="(group, index) in groups" :key="index">
            <div class="menu-group" @click.prevent="toggleGroup(group)">
              <mdicon :name="isGroupExtended(group) ? 'chevron-down' : 'chevron-right'"></mdicon>
              <span>{{ $t('views.menu.' + group.name) }}</span>
            </div>
            <div v-if="isGroupExtended(group)">
              <router-link v-for="(item, index) in accessibleLinks.filter(link => group.items.includes(link.name))" :key="item.name" :class="is_active(item) ? 'menu-item-active' : 'menu-item'" :to="{ name: item.name }">
                <mdicon v-if="false" class="text-center" :name="item_icon(item)" />
                <CBadge v-if="item.name=='contact_messages'" :count="contact_messages_unread_count" />
                {{ $t('views.route.' + item.name) }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div>
          <h3 class="header-a">{{ $t('views.route.' + $route.name) }}</h3>
        </div>
        <router-view />
      </div>
    </div>
    <div class="sm-only">
      <div class="h-14">
      </div>
      <div class="sm-footer">
        <router-link
          v-for="(item, index) in footer_menus"
          :to="{name: item.name}"
          :class="is_active(item) ? 'sm-menu-item-active' : 'sm-menu-item'"
        >
          <mdicon class="block-icon" :name="item_icon(item)" />
          <CBadge v-if="item.name=='contact_messages'" :count="contact_messages_unread_count" />
          {{ $t('views.route.' + item.name) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from './src/common/Settings'
import ApiRequestor from './src/common/ApiRequestor'
export default {
  name: "App",
  data() {
    return {
      processing: false,
      contact_messages_unread_count: 0,
      groups: Settings.menuGroups(),
      menu_item_3th: { name: 'visa_select', icon: 'passport', icon_fixed: true },
    }
  },
  computed: {
    languages(){
      return [
        { value: 'vi', label: this.$t('views.language.vi'), icon: 'fi fi-vn' },
        { value: 'ja', label: this.$t('views.language.ja'), icon: 'fi fi-jp' },
        { value: 'en', label: this.$t('views.language.en'), icon: 'fi fi-en' },
      ]
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    accessibleLinks() {
      return Settings.accessibleLinks(this.isLoggedIn, this.$i18n.locale);
    },
    footer_menus() {
      let items = [
        { name: 'home', icon: 'home' },
        //{ name: 'posts', icon: 'post' },
        { name: 'scoring', icon: 'counter', icon_fixed: true },
        this.menu_item_3th,
        this.isLoggedIn ? { name: 'contact_messages', icon: 'email-edit' } : { name: 'contact_message', icon: 'email-edit' },
        //{name: 'profile', icon: 'account'},
        //{name: 'order_form', icon: 'rocket-launch'},
        //{name: 'orders', icon: 'file-clock'},.push({ name: 'login', icon: 'book-arrow-left' }) : ,
        {name: 'menu', icon: 'view-grid'},
      ]
      // replace 4th item of menu items with current path
      if(!items.find(link => link.name === this.$route.name)) {
        let targetLink = (this.accessibleLinks).find(link => link.name === this.$route.name)
        if(targetLink) {
          this.menu_item_3th = targetLink
          items[2] = targetLink
        }
      }
      return items;
    },
  },
  watch: {
    $route (to, from){
      if(this.$route.name == 'contact_message_thread') {
          this.init();
          return;
      }
      // current group
      let current_group = this.groups.find(group => group.items.includes(this.$route.name))
      if(current_group) {
        current_group.open = true
      }
      if(!this.processing) {
        this.processing = true;
        setTimeout(() => {
          this.init();
          this.processing = false;
        }, 30000);
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    isCurrentGroup(group){
      let current_group = this.groups.find(group => group.items.includes(this.$route.name))
      if(current_group) {
        return group.name === current_group.name;
      }
      return false
    },
    isGroupExtended(group) {
      return group.open;
    },
    toggleGroup(group) {
      group.open = !group.open
    },
    goLanding() {
      window.location.href = '/' + this.$i18n.locale;
    },
    item_icon(item) {
      let icon = item.icon
      if(!this.is_active(item) && !item.icon_fixed) {
        icon = icon + '-outline'
      }
      return icon
    },
    item_active_class(item, prefix) {
      if(this.is_active(item)) {
        return prefix + '-active'
      }
      return prefix
    },
    is_active(item) {
      if(this.$route.name==item.name) {
        return true
      }
      if(item.alt_names && item.alt_names.includes(this.$route.name)) {
        return true
      }
      return false
    },
    init() {
      ApiRequestor.get('init').then(
        response => {
          this.contact_messages_unread_count = response.data.contact_messages_unread_count
        }
      );
    }
  },
}
</script>