import { createApp } from "vue";
import VueScreen from 'vue-screen'

import App from "../app.vue";
import router from '../src/router'
import i18n from '../src/i18n';
import store from '../src/store';
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

import CMessage from '../src/components/CMessage'
import CInputText from '../src/components/CInputText'
import CInputPassword from '../src/components/CInputPassword'
import CButton from '../src/components/CButton'
import CText from '../src/components/CText'
import CSelect from '../src/components/CSelect'
import CInputRadio from '../src/components/CInputRadio'
import CInputToggle from '../src/components/CInputToggle'
import CLabel from '../src/components/CLabel'
import CLink from '../src/components/CLink'
import CStepper from '../src/components/CStepper'
import CDate from '../src/components/CDate'
import CDatePicker from '../src/components/CDatePicker'
import CDateRange from '../src/components/CDateRange'
import CInputFile from '../src/components/CInputFile'
import CInputTag from '../src/components/CInputTag'
import CBankSelect from '../src/components/CBankSelect'
import CLanguage from '../src/components/CLanguage'
import CDeposit from '../src/components/CDeposit'
import Multiselect from '@vueform/multiselect'
import CTextArea from '../src/components/CTextArea'
import CContactMessage from '../src/components/CContactMessage'
import CBadge from '../src/components/CBadge'
import CLineChart from '../src/components/CLineChart'
import CBarChart from '../src/components/CBarChart'
import CPieChart from '../src/components/CPieChart'
import CTab from '../src/components/CTab'
import CPager from '../src/components/CPager'
import CMemberRequestor from '../src/components/CMemberRequestor'

document.addEventListener("DOMContentLoaded", () => {
    const app = createApp(App);
    app.use(VueScreen, 'tailwind')
    app.use(router)
    app.use(i18n);
    app.use(store);
    app.use(mdiVue, {
        icons: mdijs
    });

    app
    .component('CMessage', CMessage)
    .component('CInputText', CInputText)
    .component('CInputPassword', CInputPassword)
    .component('CButton', CButton)
    .component('CText', CText)
    .component('CLabel', CLabel)
    .component('CSelect', CSelect)
    .component('CInputRadio', CInputRadio)
    .component('CInputToggle', CInputToggle)
    .component('CLink', CLink)
    .component('CStepper', CStepper)
    .component('CDate', CDate)
    .component('CDatePicker', CDatePicker)
    .component('CDateRange', CDateRange)
    .component('CInputFile', CInputFile)
    .component('CInputTag', CInputTag)
    .component('CBankSelect', CBankSelect)
    .component('CLanguage', CLanguage)
    .component('CDeposit', CDeposit)
    .component('CTextArea', CTextArea)
    .component('Multiselect', Multiselect)
    .component('CContactMessage', CContactMessage)
    .component('CBadge', CBadge)
    .component('CLineChart', CLineChart)
    .component('CBarChart', CBarChart)
    .component('CPieChart', CPieChart)
    .component('CTab', CTab)
    .component('CPager', CPager)
    .component('CMemberRequestor', CMemberRequestor)

    app.mount("#app")
});
