<template>
  <div class="flex">
    <router-link
      v-for="(item, index) in items"
      :key="index"
      :to="{name: item.name}"
      :class="$route.name==item.name ? 'tab-item-active' : 'tab-item'"
    >
      <mdicon
        :name="item.icon"
        class="block-icon"
      /> 
      {{ $t('views.tab.' + item.name) }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CTab",
  props: {
    items: Array
  }
}
</script>