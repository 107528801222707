<template>
  <c-tab
    :items="items"
  />
</template>

<script>
export default {
  name: "Tabs",
  data: function () {
    return {
      items: [
        { name: 'budget_sheet_plan', icon: 'notebook-edit' },
        { name: 'budget_sheet_do', icon: 'archive-edit' },
        { name: 'budget_sheet_check', icon: 'chart-bar' },
        { name: 'budget_sheet_action', icon: 'file-chart-check' }
      ]
    }
  }
}
</script>