<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <div class="flex items-center">
      <mdicon
        v-if="preIcon"
        :name="preIcon"
        class="align-middle"
      />
      <input
        type="text"
        :disabled="disabled"
        :value="displayValue()"
        :class="classes"
        :placeholder="placeholder ? $t('views.placeholder.' + placeholder) : null"
        :maxlength="maxlength"
        :size="size"
        @input="updateValue"
      >
    </div>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CInputText",
  props: {
    type: {
      type: String,
      default: "text"
    },
    modelValue: [Number, String],
    preIcon: String,
    label: String,
    placeholder: String,
    outerClass: {
      type: String,
      default: 'spacer-y-a'
    },
    size: {
      type: [Number,String],
      default: 9
    },
    maxlength: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    errors: Array
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const updateValue = (event) => {
        context.emit('update:modelValue', event.target.value);
    }

    return { updateValue }
  },
  data() {
    return {
      classes: 'input-text-a'
    }
  },
  methods: {
    displayValue() {
      if((this.type == 'number') && this.modelValue) {
        try {
          let val = this.modelValue
          if(typeof(val) == 'string') {
            if(val[val.length-1] == ',' || val[val.length-1] == '.'){
              return val
            }
            val = Number(val.replaceAll(',',''))
            if(!val) {
             return '' 
            }
          }
          return val.toLocaleString('en-US')
        } catch (error) {
          return ''
        }
      }
      return this.modelValue
    }
  }
}
</script>