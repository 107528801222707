<template>
  <div class="card-a bg-warning">
    {{ $t('views.help.order.deposit.message') }}
  </div>
  <div class="card-a">
    <CText
      v-model="order.send_amount"
      label="order.send_amount"
      :currency="order.send_currency"
    />
  </div>
  <template
    v-for="(deposit_account, index) in deposit_accounts"
    :key="index"
  >
    <div class="card-a">
      <CText
        v-model="deposit_account.bank_name"
        label="bank.name"
      />
      <CText
        v-if="deposit_account.code"
        v-model="deposit_account.bank_code"
        label="bank.code"
      />
      <CText
        v-if="deposit_account.branch_name"
        v-model="deposit_account.branch_name"
        label="bank_branch.name"
      />
      <CText
        v-if="deposit_account.code"
        v-model="deposit_account.branch_code"
        label="bank_branch.code"
      />
      <CText
        v-model="deposit_account.holder_number"
        label="recipient.holder_number"
      />
      <CText
        v-model="deposit_account.holder_name"
        label="recipient.holder_name"
      />
      <template v-if="!pushed">
        <div class="flex items-center border-t mt-2">
          <input
            id="has_file"
            v-model="has_file"
            type="checkbox"
          >
          <label
            for="has_file"
            class="ml-2"
          >{{ $t("activerecord.attributes.order_attachment.file") }}</label>
        </div>
        <CInputFile
          v-if="has_file"
          v-model="file"
        />
      </template>
      <CButton
        label="confirm_deposit"
        :disabled="pushed"
        @click.prevent="confirm_deposit(deposit_account)"
      />
    </div>
  </template>
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: 'CDeposit',
  props: {
    orderId: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      deposit_accounts: [],
      order: {},
      has_file: false,
      file: null,
      pushed: false
    }
  },
  computed: {
    target_order_id() {
      return this.$route.query.order_id || this.orderId
    }
  },
  created() {
    this.getDepositAccounts();
  },
  methods: {
    getDepositAccounts() {
      ApiRequestor.get('current_user/deposit_accounts?order_id=' + this.target_order_id).then(
        response => {
          this.deposit_accounts = response.data.deposit_accounts
          this.order = response.data.order
        }
      );
    },
    confirm_deposit(deposit_account) {
      let formData = new FormData();
      if (this.file) {
        formData.append('file', this.file)
      }
      formData.append('bank_name', deposit_account.bank_name)
      ApiRequestor.upload('current_user/orders/' + this.target_order_id + '/confirm_deposit', formData).then(
        response => {
          this.pushed = true
        }
      );
    },
  }
}
</script>