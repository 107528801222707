<template>
  <CMessage :messages="message.base" />
  <div
    class="card-a cursor-pointer hover:bg-slate-50"
    v-for="(post,index) in posts" :key="index"
    @click.prevent="show(post.id)"
  >
    <h3 class="pl-2 border-l-4  border-primary">
      <p class="font-bold">{{ post.title }}</p>
      <p class="text-sm"><span class="text-neutral-400">{{ post.date }}</span><span v-for="(category,index2) in post.category_list" class="mx-1 bg-lime-300">{{ category }}</span></p>
    </h3>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Posts",
  data: function () {
    return {
     message: {},
     posts: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('post/articles').then(
        response => {
          this.posts = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    show(id){
      this.$router.push({name: 'post', params: {id: id}})
    }
  }
}
</script>