<template>
  <div v-if="!isLoggedIn" class="card-a bg-warning flex items-center text-danger">
    {{ this.$t('views.help.login_required') }}　
    <CLink @click.prevent="clickLogin()" label="login" />
    |
    <CLink @click.prevent="clickRegister()" label="register" />
  </div>
</template>

<script>
  import ApiRequestor from '../common/ApiRequestor'
  export default {
    name: 'CMemberRequestor',
    computed: {
      isLoggedIn() {
        return this.$store.state.auth.isLoggedIn;
      }
    },
    methods: {
      clickLogin() {
        ApiRequestor.redirectMember()
      },
      clickRegister() {
        ApiRequestor.redirectMember('register')
      }
    },
  }
</script>