<template>
  <CMessage :messages="message.base" />
  <div
    v-for="(name, index) in category_list"
    :key="index"
  >
    <h3 @click.prevent="fetch_by_category(name)">
      {{ name }}
    </h3>
    <template v-if="name == selected_category">
      <div
        v-for="(item, i) in helps"
        :key="i"
      >
        <h3>{{ item.title }} </h3>
      </div>
    </template>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Helps",
  data: function () {
    return {
      message: {},
      selected_category: null,
      category_list: [],
      helps: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('help/articles/category_list').then(
        response => {
          this.category_list = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    fetch_by_category(category_name) {
      ApiRequestor.get('help/articles?category_name=' + category_name).then(
        response => {
          this.category_list = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>