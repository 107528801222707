<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "LoginWithGoogle",
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    if(!this.isLoggedIn) {
      this.$store.dispatch("auth/login", this.$route.params.tk);
    }
    ApiRequestor.returnRedirectUrl()
  },
}
</script>