<template>
  <CMessage :messages="message.base" />
  <div
    v-if="license.end_date"
    class="card-a"
  >
    <CText v-model="license.plan.name" label="plan.name" />
    <CText v-model="license.end_date" label="license.end_date" />
    <CInputToggle v-model="license.auto_renewal" label="license.auto_renewal" />
  </div>
  <div class="card-a">
    <CText v-model="license.payment_method" label="license.payment_method" />
    <StripeCards :deletable="true" />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
import StripeCards from './StripeCards'

export default {
  name: "License",
  components: { StripeCards },
  data: function () {
    return {
      message: {},
      license: {
        end_date: null,
        payment_method: null,
        auto_renewal: null,
        plan: {}
      }
    }
  },
  computed: {
    toggleSubscriptiontWatcher() {
      return this.license.auto_renewal
    }
  },
  watch: {
    toggleSubscriptiontWatcher() {
        this.toggleSubscription()
    }
  },
  created(){
    this.show()
  },
  methods: {
    show() {
      ApiRequestor.get('current_user/license').then(
        response => {
          if(response.data) {
            this.license = response.data
          }
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    toggleSubscription(){
      ApiRequestor.put('current_user/license', {license: {auto_renewal: this.license.auto_renewal}}).then(
        response => {
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>