<template>
  <Tabs />
  <CMessage :messages="message.base" />

  <c-date-range
    v-model:from="filter.from"
    v-model:to="filter.to"
    :month-picker="true"
    :reload-fn="load"
    outer-class="card-a"
  />

  <table v-if="inc_table.month_category">
    <tr>
      <th>{{ $t('enum.budget_sheet.income_expense.inc') }}</th>
      <th />
      <th 
        v-for="(m, index) in inc_table.m_labels"
        :key="index"
        class="px-2 text-right"
      >
        {{ m }}
      </th>
    </tr>
    <template
      v-for="(c, index) in inc_table.c_labels"
    >
      <tr>
        <td>{{ $t('enum.budget_sheet.category.' + c) }}</td>
        <td>{{ $t('enum.budget_sheet.kind_of.est') }}</td>
        <td
          v-for="(m, index) in inc_table.m_labels"
          class="text-right"
        >
          {{ displayNumber(inc_table.month_category[m][c]['est']) }}
        </td>
      </tr>
      <tr>
        <td />
        <td>{{ $t('enum.budget_sheet.kind_of.ach') }}</td>
        <td
          v-for="(m, index) in inc_table.m_labels"
          class="text-right"
        >
          {{ displayNumber(inc_table.month_category[m][c]['ach']) }}
        </td>
      </tr>
      <tr class="border-b border-primary">
        <td />
        <td />
        <td
          v-for="(m, index) in inc_table.m_labels"
          :class="highlight(inc_table.month_category[m][c])"
          class="text-right"
        >
          {{ percentage(inc_table.month_category[m][c]) }}%
        </td>
      </tr>
    </template>
    <tr>
      <th>{{ $t('enum.budget_sheet.income_expense.exp') }}</th>
    </tr>
    <template
      v-for="(c, index) in exp_table.c_labels"
    >
      <tr>
        <td>{{ $t('enum.budget_sheet.category.' + c) }}</td>
        <td>{{ $t('enum.budget_sheet.kind_of.est') }}</td>
        <td
          v-for="(m, index) in exp_table.m_labels"
          class="text-right"
        >
          {{ displayNumber(exp_table.month_category[m][c]['est']) }}
        </td>
      </tr>
      <tr>
        <td />
        <td>{{ $t('enum.budget_sheet.kind_of.ach') }}</td>
        <td
          v-for="(m, index) in exp_table.m_labels"
          class="text-right"
        >
          {{ displayNumber(exp_table.month_category[m][c]['ach']) }}
        </td>
      </tr>
      <tr class="border-b border-primary">
        <td />
        <td />
        <td
          v-for="(m, index) in exp_table.m_labels"
          :class="highlight(exp_table.month_category[m][c])"
          class="text-right"
        >
          {{ percentage(exp_table.month_category[m][c]) }}%
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'
import Tabs from './Tabs'
export default {
  name: "BudgetSheetAction",
  components: { Tabs },
  data: function () {
    return {
      message: {},
      filter: {
        type: 'cmct',
        from: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() - 1
        },
        to: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1
        }
      },
      performance_table_data: {}
    }
  },
  computed: {
    inc_table() {
      return this.performance_table_data.inc || {}
    },
    exp_table() {
      return this.performance_table_data.exp || {}
    },
    filterParams() {
      let result = Object.assign({}, this.filter)
      result.from = new Date(this.filter.from.year, this.filter.from.month, 1)
      result.to = new Date(this.filter.to.year, this.filter.to.month + 1, 0)
      return result
    }
  },
  watch: {
    filter() {
      this.load()
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('current_user/budget_sheets/statistic', this.filterParams).then(
        response => {
          this.performance_table_data = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    percentage(item) {
      if(item['est'] > 0) {
        return Math.floor(item['ach'] / item['est'] * 100)
      } else {
        return 0
      }
    },
    displayNumber(n) {
      return n.toLocaleString('en-US')
    },
    highlight(item) {
      let x = this.percentage(item)
      if(x=='-') {
        return
      } else if(x >= 100) {
        return 'text-primary'
      } else if(x > 70) {
        return 'text-warning'
      } else {
        return 'text-danger'
      }
    }
  }
}
</script>