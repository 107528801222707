<template>
  <div :class="outerClass">
    <label class="relative inline-flex items-center cursor-pointer">
      <label
        v-if="label"
        class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-2"
      >{{ $t('activerecord.attributes.' + label) }}</label>
      <input type="checkbox" :value="true" :checked="modelValue" @input="updateValue" class="sr-only peer">
      <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
    </label>
     <label>{{ $t('enum.toggle.' + modelValue) }}</label>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CInputToggle",
  props: {
    modelValue: String,
    label: String,
    errors: Array
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const updateValue = (event) => {
        context.emit('update:modelValue', event.target.checked);
    }

    return { updateValue }
  }
}
</script>