<template>
  <CMessage :messages="message.base" />

  <div
    v-if="budget_sheet"
    class="card-a hover:bg-slate-50"
  >
    <div
      v-if="isCurrent && isWriting"
      class="relative"
    >
      <mdicon
        name="close"
        class="cursor-pointer absolute right-0 top-0"
        @click.prevent="close"
      />
      <div>
        <CInputTag
          v-model="budget_sheet.income_expense"
          :errors="message.income_expense"
          :options="income_expenses"
        />
        <CInputTag
          v-model="budget_sheet.category"
          :errors="message.category"
          :options="categories"
        />
        <CInputText
          v-model="budget_sheet.title"
          label="budget_sheet.title"
          :errors="message.title"
        />
        <CTextArea
          v-model="budget_sheet.note"
          label="budget_sheet.note"
          :errors="message.note"
        />
        <CInputText
          v-model="budget_sheet.amount"
          pre-icon="currency-jpy"
          :errors="message.amount"
          :maxlength="14"
          :size="14"
          type="number"
        />
        <CInputTag
          v-model="budget_sheet.interval"
          label="budget_sheet.interval"
          :errors="message.interval"
          :options="intervals"
        />
        <div
          v-if="budget_sheet.interval=='mon'"
          :class="!isMobile ? 'row' : ''"
        >
          <CDatePicker
            v-model="budget_sheet.start_datetime"
            label="budget_sheet.start_datetime"
            :month-picker="true"
            :errors="message.start_datetime"
          />
          <div class="w-[4px]" />
          <CDatePicker
            v-model="budget_sheet.end_datetime"
            label="budget_sheet.end_datetime"
            :month-picker="true"
            :errors="message.end_datetime"
          />
        </div>
        <div
          v-if="budget_sheet.interval=='onc'"
          class="row"
        >
          <CDatePicker
            v-model="budget_sheet.start_datetime"
            label="budget_sheet.start_datetime"
            :errors="message.start_datetime"
          />
        </div>
      </div>
      <div>
        <CButton
          v-if="isNew"
          label="create"
          icon="content-save"
          class="btn-a-outline"
          @click.prevent="create"
        />
        <CButton
          v-else
          label="update"
          icon="content-save"
          class="btn-a-outline"
          @click.prevent="update"
        />
        <CButton
          v-if="!isNew"
          label="destroy"
          icon="trash-can-outline"
          class="btn-danger-outline float-right"
          @click.prevent="destroy"
        />
      </div>
    </div>

    <div
      v-else
      class="relative"
    >
      <mdicon
        name="playlist-edit"
        class="cursor-pointer absolute right-0 top-0 block-icon"
        @click.prevent="write"
      />
      <p>
        {{ budget_sheet.overview1 }}
      </p>
      <p>
        {{ budget_sheet.overview2 }}
      </p> 
      <p>
        {{ budget_sheet.overview3 }}
      </p> 
      <!---
      <mdicon
        v-if="!isNew"
        name="content-copy"
        class="cursor-pointer  absolute right-0 bottom-0"
        @click.prevent="copy"
      />
      -->
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'
export default {
  name: "BudgetSheet",
  props: {
    index: Number,
    currentIndex: Number,
    budget_sheet: Object
  },
  emits: ['emitCurrentIndex','emitCopyBudgetSheet','emitReloadBudgetSheets'],
  data: function () {
    return {
      message: {},
      writing: false,
      budget_sheets: [],
    }
  },
  computed: {
    isMobile() {
     return this.$screen.width < 640 
    },
    isNew() {
      return !this.budget_sheet.id
    },
    isWriting() {
      return this.writing
    },
    isCurrent() {
      return this.currentIndex == this.index
    },
    kind_ofs() {
      return [
        { value: 'est', label: this.$t('enum.budget_sheet.kind_of.est') },
        { value: 'ach', label: this.$t('enum.budget_sheet.kind_of.ach') },
      ]
    },
    intervals() {
      return [
        { value: 'mon', label: this.$t('enum.budget_sheet.interval.mon') },
        { value: 'onc', label: this.$t('enum.budget_sheet.interval.onc') },
      ]
    },
    income_expenses() {
      return [
        { value: 'inc', label: this.$t('enum.budget_sheet.income_expense.inc') },
        { value: 'exp', label: this.$t('enum.budget_sheet.income_expense.exp') },
      ]
    },
    categories(){
      if(this.budget_sheet.income_expense=='exp') {
        return [
          { value: 'nec', label: this.$t('enum.budget_sheet.category.nec') },
          { value: 'pla', label: this.$t('enum.budget_sheet.category.pla') },
          { value: 'fin', label: this.$t('enum.budget_sheet.category.fin') },
          { value: 'edu', label: this.$t('enum.budget_sheet.category.edu') },
          { value: 'lon', label: this.$t('enum.budget_sheet.category.lon') },
          { value: 'giv', label: this.$t('enum.budget_sheet.category.giv') },
        ]
      } else if(this.budget_sheet.income_expense=='inc') {
        return [
          { value: 'act', label: this.$t('enum.budget_sheet.category.act') },
          { value: 'pas', label: this.$t('enum.budget_sheet.category.pas') },
        ]
      }
    },
    budget_sheet_params() {
      let result = Object.assign({}, this.budget_sheet)
      delete result.id
      if(result.amount) {
        result.amount = result.amount.replaceAll(',','')
      }
      if(this.budget_sheet.interval=='mon') {
        if(this.budget_sheet.start_datetime && this.budget_sheet.start_datetime.year){
          result.start_datetime = new Date(this.budget_sheet.start_datetime.year, this.budget_sheet.start_datetime.month, 1)
        }
        if(this.budget_sheet.end_datetime && this.budget_sheet.end_datetime.year){
          result.end_datetime = new Date(this.budget_sheet.end_datetime.year, this.budget_sheet.end_datetime.month + 1, 0)
        }
      }
      return result
    }
  },
  created() {
    this.writing = this.isNew
  },
  methods: {
    write() {
      this.writing = true
      this.$emit('emitCurrentIndex', this.index)
    },
    close() {
      if(this.isNew) {
        this.destroy()
      } else {
        this.writing = false
      }
    },
    create() {
      ApiRequestor.post('current_user/budget_sheets', {budget_sheet: this.budget_sheet_params}).then(
        response => {
          this.$emit('emitReloadBudgetSheets', this.index)
          this.message = {}
          this.writing = false
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    copy() {
      this.$emit('emitCopyBudgetSheet', this.index)
    },
    update() {
      ApiRequestor.put('current_user/budget_sheets/' + this.budget_sheet.id, {budget_sheet: this.budget_sheet_params}).then(
        response => {
          this.$emit('emitReloadBudgetSheets', this.index)
          this.message = {}
          this.writing = false
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    destroy() {
      if(this.isNew) {
        this.$emit('emitReloadBudgetSheets', this.index)
        return
      }
      ApiRequestor.delete('current_user/budget_sheets/' + this.budget_sheet.id).then(
        response => {
          this.$emit('emitReloadBudgetSheets', this.index)
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>