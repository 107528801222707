<template> 
  <CMessage :messages="message.base" />
  <div class="card-a title-a" v-for="(contract, index) in contracts" :key="index">
    <router-link :to="{name: this.code, params: {cid: contract.id} }">
      {{ $t('enum.contract.' + contract.code + '.term1.'+ contract.term1) }} / 
      {{ $t('enum.contract.' + contract.code + '.term2.'+ contract.term2) }}
      <span v-if="contract.term3">{{ $t('enum.contract.' + contract.code + '.term3.'+ contract.term3) }}</span>
      <div>{{ contract.created_at }}</div>
    </router-link>
  </div>
</template>
<script>
import ApiRequestor from '../../common/ApiRequestor'
export default {
  name: "Contracts",
  props :{
    code: String
  },
  data() {
    return {
      message: {},
      contracts: []
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      ApiRequestor.get('current_user/contracts?code=' + this.code).then(
        response => {
          this.contracts = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
  },
}
</script>