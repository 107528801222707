<template>
  <div class="menu mt-1">
    <div v-for="(group, index) in groups" :key="index">
      <div class="menu-group-active" @click.prevent="toggleGroup(group)">
        <mdicon :name="isGroupExtended(group) ? 'chevron-down' : 'chevron-right'"></mdicon>
        <span>{{ $t('views.menu.' + group.name) }}</span>
      </div>
      <div v-if="isGroupExtended(group)">
        <router-link v-for="(item, index) in accessibleLinks.filter(link => group.items.includes(link.name))" :key="item.name" class="menu-item" :to="{ name: item.name }">
          <mdicon v-if="item.icon" class="text-center" :name="item_icon(item)" />
          <CBadge v-if="item.name=='contact_messages'" :count="contact_messages_unread_count" />
          {{ $t('views.route.' + item.name) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from '../common/Settings'
export default {
  name: "Menu",
  data() {
    return {
      groups: Settings.menuGroups(),
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    accessibleLinks() {
      return Settings.accessibleLinks(this.isLoggedIn, this.$i18n.locale);
    },
  },
  methods: {
    isCurrentGroup(group){
      let current_group = this.groups.find(group => group.items.includes(this.$route.name))
      if(current_group) {
        return group.name === current_group.name;
      }
      return false
    },
    isGroupExtended(group) {
      return group.open;
    },
    toggleGroup(group) {
      group.open = !group.open
    },
    item_icon(item) {
      let icon = item.icon
      if(!item.icon_fixed) {
        icon = icon + '-outline'
      }
      return icon
    },
  },
}
</script>