<template>
  <Line
    v-if="localDatasets"
    :options="chartOptions"
    :data="chartData"
    :dataset-label="datasetLabel"
    :height="height"
  />
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'CLineChart',
  components: { Line },
  props: {
    labels: Array,
    dataset: Array,
    datasetLabel: String,
    datasets: Array,
    height: [Number,String]
  },
  computed: {
    localDatasets() {
      if(this.datasets) {
        return this.datasets
      } else if(this.dataset) {
        return [ { label: this.datasetLabel, data: this.dataset, tension: 0.3, pointRadius: 0, borderColor: "#c319ee", backgroundColor: "#c319ee" } ]
      } else {
        return null
      }
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: this.localDatasets
      }
    },
    chartOptions() {
      return {
        interaction: {
          intersect: false,
        },
        responsive: true
      }
    }
  }
}
</script>