<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <Datepicker
      v-model="localModelValue"
      :locale="$i18n.locale"
      :format="format()"
      :enable-time-picker="enableTimePicker"
      :day-names="dayNames"
      :month-picker="monthPicker"
      :year-picker="yearPicker"
      :placeholder="format().toUpperCase()"
      :select-text="selectText()"
      :cancel-text="cancelText()"
      :auto-apply="autoApply"
      :position="position"
      :clearable="clearable"
    />
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';

//https://vue3datepicker.com/api/props/#monthnameformat
export default {
  name: "CDatePicker",
  components: {
    Datepicker
  },
  props: {
    modelValue: [String, Date, Object],
    label: String,
    enableTimePicker: {
      type: Boolean,
      default: false
    },
    monthPicker: {
      type: Boolean,
      default: false
    },
    yearPicker: {
      type: Boolean,
      default: false
    },
    clearable: Boolean,
    autoApply: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'center'
    },
    errors: Array,
    outerClass: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data:  function () {
    return {
      formats: {
        ja: {
          date: 'yyyy/MM/dd',
          month: 'yyyy/MM',
          year: 'yyyy'
        },
        vi: {
          date: 'dd/MM/yyyy',
          month: 'MM/yyyy',
          year: 'yyyy'
        },
        en: {
          date: 'MM/dd//yyyy',
          month: 'MM/yyyy',
          year: 'yyyy'
        },
      },
      dayhNamesI18n: {
        vi: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN']
      }
    }
  },
  computed: {
    localModelValue: {
      get() {
        let val = this.modelValue
        if(this.monthPicker && typeof val === 'string' ) {
          val = new Date(val)
          val = {
            year: val.getFullYear(),
            month:  val.getMonth()
          }
        }
        return val
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    }
  },
  methods: {
    formatType() {
      let formatType = 'date'
      if(this.yearPicker) {
        formatType = 'year'
      } else if(this.monthPicker) {
        formatType = 'month'
      }
      return formatType
    },
    format() {
      return this.formats[this.$i18n.locale][this.formatType()]
    },
    dayNames() {
      return this.dayhNamesI18n[this.$i18n.locale]
    },
    selectText() {
      return this.$t('views.action.select')
    },
    cancelText() {
      return this.$t('views.action.exit')
    }
  }
}
</script>