import axios from 'axios';
import router from '../router';

import ClientService from './ClientService'

const Axios = axios.create({
  baseURL: '/api/v1/',
});

Axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(ClientService.getLocalJwt() && error.response.status == 401) {
    let originalRequest = error.config
    return Axios.put('/users/token', {}, { headers: ClientService.headers() }).then(
      response => {
        ClientService.setLocalJwt(response.data.jwt);
        originalRequest.headers = ClientService.headers();
        return Axios.request(originalRequest)
      },
      error => {
        ClientService.destroyLocalJwt();
        window.location.reload();
      }
    )
  } else if(error.response.status == 403) {
    router.push('/profile');
  } else if(error.response.status == 406) {
    // stop
  }
  return Promise.reject(error);
});

class ApiRequestor {
  
  redirectMember(router_name = 'login', redirect_url=null) {
    var key = 'redirect_url'
    var url = redirect_url || window.location.href
    ClientService.setLocalVal(key, url, 1)
    router.push({ name: router_name});
  }

  returnRedirectUrl() {
    var key = 'redirect_url'
    var url = ClientService.getLocalVal(key)
    ClientService.destroyLocalValue(key)
    if(url) {
      window.location.href = url
    } else {
      router.push({name: 'home'});
    }
  }

  setLanguage(language) {
    ClientService.setLanguage(language)
    if(ClientService.getLocalJwt()) {
      this.put('current_user/user_setting', { user_setting: {language: language} })
    }
  }

  getLanguage() {
    var language = ClientService.getLanguage()
    if (!language && ClientService.getLocalJwt()) {
      this.get('current_user/user_setting').then(
        response => {
          language = response.data.language
        }
      );
    }
    return language || 'vi';
  }

  async post(url, json) {
    return await Axios
      .post(url, json, { headers: ClientService.headers() });
  }

  async put(url, json) {
    return await Axios
      .put(url, json, { headers: ClientService.headers() });
  }

  async delete(url) {
    return await Axios
      .delete(url, { headers: ClientService.headers() });
  }

  async get(url, params = null) {
    return await Axios
      .get(url, { params: params, headers: ClientService.headers() });
  }

  async upload(url, file) {
    var headers = ClientService.headers();
    headers['Content-Type'] = 'multipart/form-data'
    return await Axios.post(url, file, {
      headers: headers
    })
  }

  download(url, filename) {
    Axios.get(url, {
      responseType: 'blob',
      headers: ClientService.headers()
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    })
  }

}

export default new ApiRequestor();