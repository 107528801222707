<template> 
  <span v-html="$t('views.about')" />
  <Plans />
</template>
<script>
import Plans from './plans/Plans'
export default {
  name: "About",
  components: { Plans },
}
</script>