<template>
  <div>
    <CLink
      v-if="isLoggedIn"
      :to="{name: 'recipients'}"
      translated
      :label="$t('views.action.back')"
      icon="arrow-left"
      class="btn-a-outline"
    />
    <CMessage :messages="message.base" />
    <div
      v-if="step==0"
      class="card-a"
    >
      <CSelect
        v-model="recipient.relationship"
        label="recipient.relationship"
        :errors="message.relationship"
        :options="relationships"
        :can-clear="false"
        :can-deselect="false"
      />
      <CSelect
        v-model="recipient.currency"
        label="recipient.currency"
        :errors="message.currency"
        :options="currencies"
        :can-clear="false"
      />
      <CBankSelect
        v-model="recipient"
        label="bank.name"
        :currency="recipient.currency"
        :branch-required="recipient.currency=='jpy'"
      />
      <CInputText
        v-model="recipient.holder_number"
        label="recipient.holder_number"
        :errors="message.holder_number"
      />
      <CInputText
        v-model="recipient.holder_name"
        label="recipient.holder_name"
        :errors="message.holder_name"
      />
      <CButton
        label="create"
        @click.prevent="create"
      />
    </div>
    <div v-if="step==1">
      {{ $t('views.help.recipient.create.success') }}
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "Recipient",
  data: function () {
    return {
      message: {},
      recipient: {
        currency: 'vnd',
        bank_id: '',
        bank_branch_id: '',
        holder_name: '',
        holder_number: ''
      },
      relationships: [
        { value: 'myself', label: this.$t('enum.recipient.relationship.myself') },
        { value: 'someone', label: this.$t('enum.recipient.relationship.someone') },
        { value: 'organization', label: this.$t('enum.recipient.relationship.organization') }
      ],
      currencies: [
        { value: 'jpy', label: this.$t('enum.recipient.currency.jpy'), icon: 'fi fi-jp' },
        { value: 'vnd', label: this.$t('enum.recipient.currency.vnd'), icon: 'fi fi-vn' }
      ],
      step: 0
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  methods: {
    create() {
      ApiRequestor.post('current_user/recipients', {recipient: this.recipient}).then(
        response => {
          this.message = {}
          this.$router.push({name: 'recipients'})
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>