<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Logout",
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      ApiRequestor.delete('current_user/sessions/logout');
      this.$store.dispatch('auth/logout');
      this.$router.push({name: 'login'});
    }
  }
}
</script>