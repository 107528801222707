<template>
  <div :class="outerClass">
    <label class="label-a">{{ $t('activerecord.attributes.' + label) }}</label>
    <div>
      <Multiselect
        v-for="(item, index) in items"
        :key="index"
        v-model="date[item]"
        :searchable="true"
        :options="item_options[item]"
        :value="date[item]"
        :can-clear="false"
        :placeholder="$t('views.placeholder.date.' +item)"
      />
    </div>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CDate",
  props: {
    modelValue: [String, Date],
    label: String,
    outerClass: {
      type: String,
      default: 'spacer-y-a'
    },
    errors: Array
  },
  emits: ['update:modelValue'],
  data() {
    return {
      date: {
        year: '',
        month: '',
        day: ''
      }
    }
  },
  computed: {
    formattedValue() {
      return this.date.year + "-" + this.date.month + "-" + this.date.day
    },
    item_options(){
      return {
        year: this.range(1900, new Date().getFullYear() - 12, true),
        month: this.range(1,12),
        day: this.range(1,31)
      }
    },
    items(){
      switch (this.$i18n.locale) {
        case 'vi':
          return ['day', 'month', 'year']
        case 'ja':
          return ['year', 'month', 'day']
        default:
          return ['month', 'day', 'year']
      }
    }
  },
  watch: {
    formattedValue(value, oldValue) {
      this.$emit('update:modelValue', value);
    }
  },
  created() {
    if(this.modelValue) {
      this.date.year = Number(this.modelValue.substring(0, 4));
      this.date.month = Number(this.modelValue.substring(5, 7));
      this.date.day = Number(this.modelValue.substring(8, 10));
    }
  },
  methods: {
    range(start, end, reverse = false) {
      var arr = [...Array(end - start + 1).keys()].map(i => i + start);
      if (reverse) arr.reverse();
      return arr;
    }
  }
}
</script>