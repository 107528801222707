<template>
  <p
    v-if="messages"
    :class="classes[type]"
  >
    {{ messages }}
  </p>
</template>

<script>
export default {
  name: "CMessage",
  props: {
    type: {
      type: String,
      default: 'error'
    },
    messages: String
  },
  data() {
    return {
      classes: {
        error: 'message-error',
        success: 'message-success'
      }
    }
  }
}
</script>