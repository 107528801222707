const Cookie = require('js-cookie')

class ClientService {

  headers() {
    let token = this.getLocalJwt() || ''
    return {
      "Accept-Language": this.getLanguage() || 'vi',
      "Authorization": 'Bearer ' + token,
      "Content-Type": 'application/json'
    }
  }

  setLocalJwt(jwt) {
    Cookie.remove('jwt')
    Cookie.set('jwt', jwt,  { expires: 365 })
  }

  getLocalJwt() {
    return Cookie.get('jwt') ? Cookie.get('jwt') : undefined
  }

  setERM(val) {
    try {
      this.setLocalVal('er.mer', JSON.stringify(val))
    } catch(error) {
    }
  }

  getERM() {
    let result = undefined
    try {
      result = JSON.parse(this.getLocalVal('er.mer'))
    } catch(error) {
      Cookie.remove('er.mer')
    }
    return result
  }

  setLocalVal(key, val, expires = 365) {
    Cookie.remove(key)
    Cookie.set(key, val,  { expires: expires })
  }

  getLocalVal(key) {
    return Cookie.get(key) ? Cookie.get(key) : undefined
  }

  destroyLocalValue(key) {
    Cookie.remove(key)
  }

  destroyLocalJwt() {
    Cookie.remove('jwt')
  }

  setLanguage(language) {
    Cookie.remove('language')
    Cookie.set('language', language,  { expires: 365 })
  }

  getLanguage() {
    return Cookie.get('language') ? Cookie.get('language') : undefined
  }

}

export default new ClientService();
