<template>
  <template v-if="step==0">
    <CLink
      label="display_session"
      @click.prevent="generate_otp()"
    />
  </template>
  <template v-if="step==1">
    <div class="card-a bg-warning">
      {{ $t('views.help.otp.generate') }}
    </div>
    <CLink
      label="generate_otp"
      @click.prevent="generate_otp()"
    />
    <CMessage
      :messages="message.success"
      type="success"
    />
    <CMessage :messages="message.base" />
    <CInputText
      v-model="otp"
      label="user.otp"
      :errors="message.otp"
    />
    <CButton
      label="confirm"
      @click.prevent="load()"
    />
  </template>
  <div
    v-for="(session, index) in sessions"
    :key="index"
    class="card-a"
  >
    <CText v-model="session.user_agent" />
    <CText v-model="session.created_at" />
    <CButton
      :label="$t('views.route.logout')"
      :translated="true"
      icon="logout"
      class="btn-danger-outline spacer-t-a"
      @click.prevent="destroy(session.id)"
    />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "Sessions",
  data: function () {
    return {
      message: {},
      step: 0,
      otp: '',
      sessions: []
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    otpParams() {
      return {
        otp: this.otp
      }
    }
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    load() {
      ApiRequestor.get('current_user/sessions', this.otpParams).then(
        response => {
          this.sessions = response.data
          this.message = {}
          this.step++
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    generate_otp() {
      ApiRequestor.post('users/otp', {otp: this.otpParams}).then(
        response => {
          if(this.step == 0) {
            this.step = 1
          } else {
            this.message = { success: this.$t('views.success.generate_otp') }
          }
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    destroy(id) {
      ApiRequestor.delete('current_user/sessions/' + id).then(
        response => {
          this.sessions = response.data
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>