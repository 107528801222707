<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <div class="flex justify-center items-center w-full">
      <label
        :for="label"
        class="flex flex-col justify-center items-center w-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <span
          v-if="modelValue"
          class="text-primary"
        >
          <mdicon name="attachment" />
          <div v-for="(item, index) in modelValue" :key="index">[{{ item.name }}]</div>
        </span>
        <div class="flex flex-col justify-center items-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            class="mb-3 w-10 h-10 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          /></svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to select</span></p>
          <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or GIF<br>(MAX SIZE: 5MB)</p>
        </div>
        <input
          :id="label"
          type="file"
          class="inline invisible"
          :multiple="multiple"
          @change="updateValue"
        >
      </label>
    </div>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CInputFile",
  props: {
    modelValue: [String, File],
    multiple: {
      type: Boolean,
      default: false
    },
    label: String,
    outerClass: {
      type: String,
      default: 'spacer-t-a'
    },
    errors: Array
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const updateValue = (event) => {
        context.emit('update:modelValue', event.target.files[0]);
    }

    return { updateValue }
  }
}
</script>