<template>
  <CMessage :messages="message.base" />
  <div class="card-a text-primary flex">
    <mdicon
      name="filter-variant"
      class="cursor-pointer"
      @click.prevent="toggleOpenFilter()"
    />
    <div v-if="!filterOpening">
      {{ filterOverview }}
      <mdicon
        v-if="filterOverview"
        name="close"
        class="cursor-pointer"
        @click.prevent="clearFilter()"
      />
    </div>
    <div v-else>
      <c-input-tag
        v-model="filter.income_expense"
        :options="income_expenses"
        :required="false"
      />
    
      <c-input-tag
        v-model="filter.category"
        :options="categories"
        :required="false"
      />
      <c-button
        label="save"
        @click.prevent="saveFilter()"
      />
    </div>
  </div>

  <c-button
    label="new"
    icon="plus-circle"
    class="btn-a-outline"
    @click.prevent="add"
  />

  <BudgetSheet
    v-for="(budget_sheet, index) in budget_sheets"
    :key="budget_sheet.id"
    :budget_sheet="budget_sheet"
    :current-index="current_index"
    :index="index"
    @emitCurrentIndex="emitCurrentIndex"
    @emitCopyBudgetSheet="emitCopyBudgetSheet"
    @emitReloadBudgetSheets="emitReloadBudgetSheets"
  />

  <c-pager
    v-model="filter.page"
    :per="filter.per"
    :total-count="budget_sheets_count"
  />
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'
import BudgetSheet from './BudgetSheet'
export default {
  name: "BudgetSheets",
  components: { BudgetSheet },
  props: {
    kind_of: String
  },
  data: function () {
    return {
      message: {},
      budget_sheets: [],
      budget_sheets_count: 0,
      current_index: 0,
      filter: {
        page: 1,
        per: 5,
        income_expense: null,
        category: null
      },
      filterOpening: false
    }
  },
  computed: {
    income_expenses() {
      return [
        { value: 'inc', label: this.$t('enum.budget_sheet.income_expense.inc') },
        { value: 'exp', label: this.$t('enum.budget_sheet.income_expense.exp') },
      ]
    },
    categories(){
      if(this.filter.income_expense=='exp') {
        return [
          { value: 'nec', label: this.$t('enum.budget_sheet.category.nec') },
          { value: 'pla', label: this.$t('enum.budget_sheet.category.pla') },
          { value: 'fin', label: this.$t('enum.budget_sheet.category.fin') },
          { value: 'edu', label: this.$t('enum.budget_sheet.category.edu') },
          { value: 'lon', label: this.$t('enum.budget_sheet.category.lon') },
          { value: 'giv', label: this.$t('enum.budget_sheet.category.giv') },
        ]
      } else if(this.filter.income_expense=='inc') {
        return [
          { value: 'act', label: this.$t('enum.budget_sheet.category.act') },
          { value: 'pas', label: this.$t('enum.budget_sheet.category.pas') },
        ]
      }
    },
    filterParams(){
      return {
        page: this.filter.page,
        per: this.filter.per,
        kind_of: this.kind_of,
        income_expense: this.filter.income_expense,
        category: this.filter.category,
      }
    },
    pageWatcher() {
      return {
        page: this.filter.page,
        per: this.filter.per
      }
    },
    filterOverview() {
      let res = ''
      if(this.filter.income_expense){
        res = res + this.$t('enum.budget_sheet.income_expense.' + this.filter.income_expense)
      }
      if(this.filter.category){
        res = res + ' / ' + this.$t('enum.budget_sheet.category.' + this.filter.category)
      }
      return res
    }
  },
  watch: {
    pageWatcher() {
      this.load()
    }
  },
  created() {
    this.load()
  },
  methods: {
    emitCurrentIndex(index) {
      if(index > 0 && this.budget_sheets[0] && !this.budget_sheets[0].id) {
        this.removeBudgetSheet(0)
        index = index - 1
      }
      this.current_index = index
    },
    emitCopyBudgetSheet(index){
      let newOne = Object.assign({}, this.budget_sheets[index])
      newOne.id = null
      this.budget_sheets = [newOne,...this.budget_sheets]
      this.current_index = 0
    },
    emitReloadBudgetSheets(index) {
      this.current_index = -1
      this.filter.page = 1
      this.load()
    },
    removeBudgetSheet(index) {
      this.budget_sheets.splice(index, 1)
    },
    add() {
      if(this.budget_sheets.length > 0 && !this.budget_sheets[0].id) {
        return
      }
      let new_one = {
        kind_of: this.kind_of,
        income_expense: this.filter.income_expense || 'exp',
        category: this.filter.category,
        interval: 'mon'
      }
      let current_year = (new Date()).getFullYear()
      let current_month = (new Date()).getMonth()
      if(new_one.kind_of=='ach') {
        new_one.interval = 'onc'
      }
      if(new_one.interval=='mon') {
        new_one.start_datetime = {
          year: current_year,
          month: current_month
        }
        new_one.end_datetime = {
          year: current_year,
          month: 11
        }
      } else if(new_one.interval=='onc') {
        new_one.start_datetime = new Date()
      }
      this.budget_sheets = [new_one,...this.budget_sheets]
      this.current_index = 0
    },
    toggleOpenFilter() {
      this.filterOpening = !this.filterOpening
    },
    clearFilter() {
      this.filter.income_expense = null
      this.filter.category = null
      this.load()
    },
    saveFilter() {
      if(this.filter.page == 1) {
        this.load()
      } else {
        this.filter.page = 1
      }
    },
    load() {
      ApiRequestor.get('current_user/budget_sheets', this.filterParams).then(
        response => {
          this.budget_sheets = response.data.budget_sheets
          this.budget_sheets_count = response.data.pagination.total_count
        },
        error => {
          this.message = error.response.data
        }
      );
      this.filterOpening = false
    }
  }
}
</script>