<template>
  <div class="article">
    <CMessage :messages="message.base" />
    <h3>{{ help.title }}</h3>
    <div v-html="help.body" />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Help",
  data: function () {
    return {
     message: {},
      help: {
        title: '',
        body: ''
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('help/articles/' + this.$route.query.id).then(
        response => {
          this.help = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>