<template>
  <div class="article">
    <div v-html="terms.body" />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Laws",
  data: function () {
    return {
      message: "",
      terms: {
        title: '',
        body: ''
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('law/articles/latest').then(
        response => {
          this.terms = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>