<template>
  <div
    v-if="pages && pages.length > 1"
    class="flex"
  >
    <div
      v-for="(page, index) in pages"
      :key="index"
      :class="pageClass(page)"
      @click.prevent="setPage(page)"
    >
      {{ page }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CPager",
  props: {
    modelValue: Number,
    per: Number,
    totalCount: Number,
    prevText: {
      type: String,
      default: 'PREV'
    },
    nextText: {
      type: String,
      default: 'NEXT'
    }
  },
  emits: ['update:modelValue'],
  computed: {
    pages() {
      return Array.from({length: Math.ceil(this.totalCount/this.per)}, (_, i) => i + 1)
    }
  },
  methods: {
    setPage(page) {
      this.$emit('update:modelValue', page)
    },
    pageClass(page) {
      return this.modelValue == page ? 'page-item-active' : 'page-item'
    },
    prev() {
      let p = Math.max(this.modelValue + 1, 1)
      this.setPage(p)
    },
    next() {
      let p = Math.min(this.modelValue + 1, this.pages.length)
      this.setPage(p)
    }
  },
}
</script>