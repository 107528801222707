<template>
  <div :class="outerClass">
    <template
      v-for="(step, index) in steps.names"
      :key="index"
    >
      <CLink
        :class="classes(index)"
        :label="translated ? step : $t('views.stepper.' + step)"
        :translated="true"
        :icon="icon(index)"
        :append-icon="appendIcon(index)"
      />
    </template>
  </div> 
</template>

<script>
export default {
  name: "CStepper",
  props: {
    steps: Object,
    translated: Boolean,
    outerClass: {
      type: String,
      default: 'flex mb-4'
    }
  },
  methods: {
    status(index) {
      if (index < this.steps.current) {
        return 'previous'
      }
      if (index == this.steps.current) {
        return 'current'
      }
      if (index > this.steps.current) {
        return 'next'
      }
    },
    appendIcon(index) {
      return this.status(index) == 'previous' ? 'check' : null
    },
    icon(index) {
      switch (this.status(index)) {
        case 'current':
          return 'numeric-' + (index + 1) + '-circle';
        case 'previous':
        case 'next':
          return 'numeric-' + (index + 1) + '-circle-outline';
      }
    },
    classes(index) {
      switch (this.status(index)) {
        case 'current':
          return 'text-primary mr-2'
        case 'previous':
          return 'text-primary opacity-50 mr-2'
        case 'next':
          return 'font-light mr-2'
      }
    }
  }
}
</script>