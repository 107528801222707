<template>
  <Tabs />
  <BudgetSheets
    kind_of="ach"
  />
</template>

<script>
import BudgetSheets from './BudgetSheets'
import Tabs from './Tabs'
export default {
  name: "BudgetSheetDo",
  components: { BudgetSheets, Tabs },
}
</script>