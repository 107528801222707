<template>
  <div :class="classes">
    <div>
      <label class="label-a">{{ $t('activerecord.models.bank') }}</label>
      <Multiselect
        ref="bank_select"
        v-model="localModelValue.bank_id"
        :value="modelValue.bank_id"
        :options="banks"
        value-prop="id"
        label="display_name"
        :searchable="true"
        :loading="processing"
        :placeholder="$t('views.placeholder.bank.display_name')"
        :can-clear="false"
        :can-deselect="false"
        @search-change="bank_searcher()"
        @select="select_bank()"
      />
    </div>
    <div v-if="branchRequired">
      <label class="label-a">{{ $t('activerecord.models.bank_branch') }}</label>
      <Multiselect
        ref="bank_branch_select"
        v-model="localModelValue.bank_branch_id"
        :value="modelValue.bank_branch_id"
        :options="bank_branches"
        value-prop="id"
        label="display_name"
        :searchable="true"
        :loading="processing"
        :placeholder="$t('views.placeholder.bank_branch.display_name')"
        :can-clear="false"
        :can-deselect="false"
        @search-change="bank_branch_searcher()"
      />
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "CBankSelect",
  props: {
    modelValue: Object,
    label: String,
    currency: {
      type: String,
      default: "vnd"
    },
    branchRequired: {
      type: Boolean,
      default: false
    },
    classes: String,
    errors: Array
  },
  emits: ['update:modelValue'],
  data() {
    return {
      banks: [],
      bank_branches: [],
      processing: false
    }
  },
  computed: {
    localModelValue: {
      get() {
          return this.modelValue
      },
      set(newValue) {
          this.$emit('update:modelValue', newValue)
      },
    }
  },
  watch: {
    currency(value, oldValue) {
      this.localModelValue.bank_id = null;
      this.bank_searcher();
    }
  },
  mounted() {
    this.searchBanks();
  },
  methods: {
    bank_searcher() {
      if(!this.processing) {
        this.processing = true;
        setTimeout(() => {
          var searcher = this.$refs.bank_select.$refs.input.value
          this.searchBanks(searcher);
          this.processing = false;
        }, 500);
      }
    },
    bank_branch_searcher() {
      if(!this.processing) {
        this.processing = true;
        setTimeout(() => {
          var searcher = this.$refs.bank_branch_select.$refs.input.value
          this.searchBankBranches(searcher);
          this.processing = false;
        }, 500);
      }
    },
    searchBanks(searcher) {
      ApiRequestor.get('current_user/' + this.currency + '/banks', { searcher: searcher }).then(
        response => {
          this.banks = response.data
        }
      );
    },
    select_bank() {
      if (this.branchRequired) {
        this.bank_branch_searcher()
        this.localModelValue.bank_branch_id = ''
      }
    },
    searchBankBranches(searcher) {
      ApiRequestor.get('current_user/' + this.currency + '/banks/' + this.modelValue.bank_id + '/bank_branches', { searcher: searcher }).then(
        response => {
          this.bank_branches = response.data
        }
      );
    }
  }
}
</script>