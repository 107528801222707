<template>
  <div>
    <div
      v-if="!profile.unloaded && profile.status!='approved'"
      class="card-a bg-warning"
    >
      {{ $t('views.help.profile.status.' + (profile.status || 'editing')) }}
    </div>
    
    <CMessage :messages="message.base" />

    <div
      v-if="update_mode && profile.is_editable"
      class="card-a"
    >
      <CSelect
        v-model="profile.nationality"
        label="profile.nationality"
        :errors="message.nationality"
        :options="nationalities"
        :can-clear="false"
      />

      <CSelect
        v-model="profile.country"
        label="profile.country"
        :errors="message.country"
        :options="nationalities"
        :can-clear="false"
      />

      <div
        v-if="display_zipcode()"
        class="flex"
      >
        <CInputText
          v-model="zipcode"
          :errors="message.zipcode"
          :maxlength="8"
          placeholder="profile.zipcode"
        />
        <CLink
          icon="magnify"
          @click.prevent="search_zip_code()"
        />
      </div>
      
      <CInputText
        v-model="profile.address"
        label="profile.address"
        :errors="message.address"
        placeholder="profile.address"
        :maxlength="100"
      />
      <CInputText
        v-if="need_kana()"
        v-model="profile.address_kana"
        label="profile.address_kana"
        :errors="message.address_kana"
        placeholder="profile.address_kana"
        :maxlength="100"
      />

      <div class="flex">
        <CInputText
          v-model="profile.last_name"
          label="profile.last_name"
          :errors="message.last_name"
          placeholder="profile.last_name"
        />
        <div class="ml-1" />
        <CInputText
          v-model="profile.first_name"
          label="profile.first_name"
          :errors="message.first_name"
          placeholder="profile.first_name"
        />
      </div>

      <div
        v-if="need_kana()"
        class="flex"
      >
        <CInputText
          v-model="profile.last_name_kana"
          label="profile.last_name_kana"
          :errors="message.last_name_kana"
          placeholder="profile.last_name_kana"
        />
        <div class="ml-1" />
        <CInputText
          v-model="profile.first_name_kana"
          label="profile.first_name_kana"
          :errors="message.first_name_kana"
          placeholder="profile.first_name_kana"
        />
      </div>

      <CInputRadio
        v-model="profile.gender"
        label="profile.gender"
        :errors="message.gender"
        :options="genders"
      />

      <CDatePicker
        v-model="profile.birthday"
        label="profile.birthday"
        :errors="message.birthday"
      />

      <div class="flex">
        <CButton
          class="btn-a-outline mr-8"
          :label="$t('views.action.back')"
          :translated="true"
          icon="arrow-left"
          @click.prevent="cancel()"
        />
        <CButton
          label="update"
          @click.prevent="update"
        />
      </div>
    </div>
    <div
      v-else-if="!profile.unloaded"
      class="card-a"
    >
      <CMessage
        v-if="profile.contact_message"
        :messages="profile.contact_message.title"
      />
      <CText
        v-model="profile.email"
        label="user.email"
      />
      <CText
        v-model="profile.nationality"
        label="profile.nationality"
        :enum="true"
      />
      <CText
        v-model="profile.fullname"
        label="profile.fullname"
      />
      <CText
        v-if="need_kana()"
        v-model="profile.fullname_kana"
        label="profile.fullname_kana"
      />
      <CText
        v-model="profile.gender"
        label="profile.gender"
        :enum="true"
      />
      <CText
        v-model="profile.birthday"
        label="profile.birthday"
        date
      />
      <CText
        v-model="profile.country"
        label="profile.country"
        :enum="true"
      />
      <CText
        v-model="profile.address"
        label="profile.address"
      />
      <CText
        v-if="need_kana()"
        v-model="profile.address_kana"
        label="profile.address_kana"
      />
      <CText
        v-model="profile.status"
        label="profile.status"
        :enum="true"
      />
      <CButton
        v-if="profile.is_editable"
        class="btn-a-outline spacer-t-a"
        icon="playlist-edit"
        label="edit"
        @click.prevent="update_mode=true"
      />    
      <CButton
        v-if="profile.status=='editing'&&profile.attachments.length==0"
        class="btn-danger-outline ml-8"
        label="restore"
        icon="restore" 
        @click.prevent="restore()"
      />
    </div>

    <div
      v-if="profile.is_uploadable"
      class="card-a"
    >
      <h3>{{ $t('activerecord.models.profile_attachment') }}</h3>
      <div class="grid grid-cols-2">
        <img
          v-for="(item, index) in profile.attachments"
          :key="index"
          :src="item"
        >
        <CInputFile
          v-model="files[0]"
          label="profile_attachment.front_side"
        />
        <CInputFile
          v-model="files[1]"
          label="profile_attachment.back_side"
        />
      </div>
      <CMessage :messages="message.base" />
      <CMessage :messages="message.file" />
      <CButton
        label="upload"
        :disabled="!selected()"
        @click.prevent="upload"
      />
    </div>

    <CButton
      v-if="profile.is_uploadable"
      label="submit"
      :disabled="profile.attachments.length==0"
      @click.prevent="submit"
    />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "Profile",
  data: function () {
    return {
      message: {},
      profile: {
        unloaded: true,
        first_name:'',
        first_name_kana:'',
        last_name:'',
        last_name_kana:'',
        gender:'',
        birthday:'',
        address:'',
        address_kana:'',
      },
      zipcode: '',
      genders: [
        { value: 'male', label: this.$t('enum.profile.gender.male') },
        { value: 'female', label: this.$t('enum.profile.gender.female') }
      ],
      nationalities: [
        { value: 'vn', label: this.$t('enum.profile.nationality.vn'), icon: 'fi fi-vn' },
        { value: 'jp', label: this.$t('enum.profile.nationality.jp'), icon: 'fi fi-jp' }
      ],
      files: [],
      update_mode: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    profile_params() {
      var params = {...this.profile}
      delete params.status
      delete params.fullname
      delete params.is_editable
      delete params.is_uploadable
      return params
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.load();
    } else {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    display_zipcode() {
      return this.profile.country == 'jp'
    },
    need_kana() {
      return this.profile.country == 'jp' || this.profile.nationality == 'jp'
    },
    selected() {
      return this.files.length >= 2  && this.files[0] && this.files[1]
    },
    search_zip_code() {
      ApiRequestor.get('current_user/zipcodes/' + this.zipcode).then(
        response => {
          if(response.data) {
            this.profile.address = response.data.prefecture + response.data.city + response.data.town
            this.zipcode = ''
          } else {
            this.message = { zipcode: [this.$t('views.failure.not_found')] }
          }
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    load() {
      ApiRequestor.get('current_user/profile').then(
        response => {
          this.profile = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    update() {
      ApiRequestor.put('current_user/profile', {profile: this.profile_params}).then(
        response => {
          this.profile = response.data
          this.update_mode = false
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    submit() {
      ApiRequestor.post('current_user/profile/submit', {profile: this.profile_params}).then(
        response => {
          this.profile = response.data
          this.update_mode = false
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    cancel() {
      this.update_mode = false
      this.load()
    },
    restore() {
      ApiRequestor.delete('current_user/profile').then(
        response => {
          this.profile = response.data
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    upload() {
      let formData = new FormData();
      formData.append('files[]', this.files[0])
      formData.append('files[]', this.files[1])
      ApiRequestor.upload('current_user/profile_attachments', formData).then(
        response => {
          this.profile = response.data
          this.files = []
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>