<template>
  <CMessage :messages="message.base" />
  <div class="article">
    <h3 class="pl-2 border-l-4  border-primary font-bold">{{ post.title }}</h3>
    <span v-for="(category,index) in post.category_list" class="mx-1 bg-lime-300">{{ category }}</span>
    <p class="text-sm text-neutral-400"><mdicon name="clipboard-text-clock" />{{ post.date }}</p>
    <div class="mt-3" v-html="post.body" />
    <div v-if="post.is_trailer" class="card-a">
      <h3>{{ this.$t('views.help.login_required_to_see_more') }}</h3>
      <CLink
        label="has_registered"
        :to="{name: 'login'}"
      />
      <CLink
        label="has_not_registered"
        :to="{name: 'register'}"
      />
    </div>
  </div>
  <CLink
    :to="{name: 'posts'}"
    translated
    :label="$t('views.action.back')"
    icon="arrow-left"
  />
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Post",
  data: function () {
    return {
     message: {},
     id: this.$route.params.id,
     post: {
        title: '',
        body: ''
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('post/articles/' + this.id).then(
        response => {
          this.post = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>