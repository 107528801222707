<template>
  <div>
    <CStepper :steps="steps" />
    <CMessage :messages="message.base" />
    <div v-if="steps.current==0">
      <CInputText
        v-model="user.email"
        label="user.email"
        :errors="message.email"
      />
      <CButton
        label="send"
        @click.prevent="submit"
      />
    </div>

    <div v-if="steps.current==1">
      <div class="card-a bg-warning">
        {{ $t('views.help.otp.generate') }}
      </div>
      <CInputText
        v-model="user.otp"
        label="user.otp"
        :errors="message.otp"
      />
      <CButton
        label="confirm"
        @click.prevent="confirm()"
      />
      <CLink
        label="generate_otp"
        @click.prevent="generate_otp()"
      />
      <CMessage
        :messages="message.success"
        type="success"
      />
    </div>

    <div v-if="steps.current==2">
      <CInputPassword
        v-model="user.password"
        label="user.new_password"
        :errors="message.password"
      />
      <CInputPassword
        v-model="password_repeat"
        label="user.new_password_repeat"
        :errors="message.password_repeat"
      />
      <CButton
        label="create"
        @click.prevent="generate_password()"
      />
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "ResetPassword",
  data: function () {
    return {
      message: {},
      user: {
        email: '',
        password: '',
        reset_password_token: ''
      },
      password_repeat: '',
      steps: {
        names: ['reset_password.email', 'reset_password.otp_confirm', 'reset_password.new_password'],
        current: 0
      }
    }
  },
  computed: {
    otpParams() {
      return {
        email: this.user.email,
        otp: this.user.otp
      }
    }
  },
  methods: {
    submit() {
      ApiRequestor.post('users/reset_password', {user: this.user}).then(
        response => {
          this.message = {}
          this.steps.current ++;
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    confirm() {
      ApiRequestor.post('users/reset_password/confirm', {user: this.user}).then(
        response => {
          this.user.reset_password_token = response.data.reset_password_token;
          this.message = {}
          this.steps.current ++;
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    generate_otp() {
      ApiRequestor.post('users/otp', {user: this.otpParams}).then(
        response => {
          this.message = { success: this.$t('views.success.generate_otp') }
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    generate_password() {
      if(this.user.password != this.password_repeat) {
        this.message = { password_repeat: [this.$t('views.failure.password_not_matching')] }
        return
      }
      ApiRequestor.put('users/reset_password', {user: this.user}).then(
        response => {
          this.$store.dispatch("auth/login", response.data.jwt);
          this.$router.push({ name: 'home' });
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>