<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <textarea
      :value="modelValue"
      class="input-text-a px-2 py-2"
      :rows="rows"
      :placeholder="placeholder ? $t('views.placeholder.' + placeholder) : null"
      @input="updateValue"
    />
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CTextArea",
  props: {
    rows: Number,
    modelValue: [Number, String],
    label: String,
    placeholder: String,
    outerClass: {
      type: String,
      default: 'spacer-y-a'
    },
    maxlength: Number,
    errors: Array
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const updateValue = (event) => {
        context.emit('update:modelValue', event.target.value);
    }

    return { updateValue }
  }
}
</script>