<template>
  <CButton
    v-if="count > 0"
    class="badge-a"
    :label="displayCount"
    translated
  />
</template>

<script>
  export default {
    name: 'CBadge',
    props: {
      count: Number
    },
    computed: {
      displayCount() {
        if(this.count > 9) {
          return '9+'
        }
        return this.count
      }
    }
  }
</script>