<template> 
  <Contract :code="code"></Contract>
</template>
<script>
import Contract from './Contract.vue';
export default {
  name: "ContractSelect",
  props :{
    code: String
  },
  components: { Contract }
}
</script>