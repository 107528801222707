<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <div class="flex items-center">
      <template
        v-for="(item, index) in options"
        :key="index"
      >
        <input
          :id="item.value"
          v-model="localModelValue"
          type="radio"
          :value="item.value"
        >
        <label
          class="ml-1 mr-2"
          :for="item.value"
        >{{ item.label }}</label>
      </template>
    </div>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CInputRadio",
  props: {
    modelValue: String,
    options: Array,
    label: String,
    outerClass: {
      type: String,
      default: 'spacer-y-a'
    },
    errors: Array
  },
  emits: ['update:modelValue'],
  computed: {
    localModelValue: {
      get() {
          return this.modelValue
      },
      set(newValue) {
          this.$emit('update:modelValue', newValue)
      },
    }
  }
}
</script>