<template>
  <router-link
    :to="to ? to : ''"
    :class="classes"
  >
    <mdicon
      v-if="icon"
      :name="icon"
    />
    <span v-if="label">{{ translated ? label : $t('views.route.' + label) }}</span>
    <mdicon
      v-if="appendIcon"
      :name="appendIcon"
    />
  </router-link>
</template>

<script>
export default {
  name: "CLink",
  props: {
    label: String,
    to: [String, Object],
    class: String,
    translated: {
      type: Boolean,
      default: false
    },
    icon: String,
    appendIcon: String
  },
  computed: {
    classes() {
      return this.class || 'link-a'
    }
  }
}
</script>