<template>
  <Pie
    v-if="datasets"
    :options="chartOptions"
    :data="chartData"
    :dataset-label="datasetLabel"
  />
</template>

<script>
import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

export default {
  name: 'CPieChart',
  components: { Pie },
  props: {
    labels: Array,
    datasets: Array
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets // [ { data: Array, backgroundColor: Array } ]
      }
    },
    chartOptions() {
      return {
        
      }
    }
  }
}
</script>