<template>
  <div :class="outerClass">
    <label class="label-a">{{ translated ? label : $t('activerecord.attributes.' + label) }}</label>
  </div>
</template>

<script>
export default {
  name: "CLabel",
  props: {
    label: String,
    translated: Boolean,
    outerClass: {
      type: String
    },
  }
}
</script>