<template>
  <CMessage :messages="message.base" />
  <table v-if="plans.length>0">
    <tr>
      <th></th>
      <th v-for="(plan, index) in plans" :key="index">{{ plan.name }}</th>
    </tr>
    <tr v-for="(item, index) in description" :key="index">
      <td>
        {{ item }}
      </td>
      <th v-for="(plan, index2) in plans" :key="index2"> {{ plan.description[index] }}</th>
    </tr>
    <tr >
      <td></td>
      <td v-for="(plan, index2) in plans " :key="index2">
        <router-link
          v-for="(plan_price, index2) in plan.plan_prices"
          :to="{ name: 'plan_register', params: {id: plan_price.id} }"
          class="btn-a" >{{ $t('views.action.purchase') }} <br> {{ plan_price.overview }}
        </router-link>
      </td>
    </tr>
  </table>
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'

export default {
  name: "Plans",
  data: function () {
    return {
      message: {},
      description: [],
      plans: []
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('plans').then(
        response => {
          this.description = response.data.description
          this.plans = response.data.plans
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>