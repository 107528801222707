class Settings {
  menuGroups() {
    return [
      { name: 'visa', items: ['visa_select', 'visas'], open: true  },
      { name: 'tools', items: ['scoring','budget_sheet_plan','exchange_rate', 'posts'], open: true },
      { name: 'membership', items: ['plans', 'license'], open: true },
      { name: 'account', items: ['profile','change_password','sessions','logout'], open: true },
      { name: 'customer', items: ['contact_message', 'contact_messages'], open: true },
      { name: 'document', items: ['terms', 'privacies','laws'], open: true }
    ]
  }
  accessibleLinks(isLoggedIn, lang='vi') {
    let links = [];
    links.push({ name: 'home', icon: 'home' });
    links.push({ name: 'scoring', icon: 'counter', icon_fixed: true });
    links.push({ name: 'budget_sheet_plan', icon: 'piggy-bank', alt_names: ['budget_sheet_do','budget_sheet_check','budget_sheet_action']});
    if(lang === 'vi') {
      links.push({ name: 'exchange_rate', icon: 'currency-jpy', icon_fixed: true });
    }
    links.push({ name: 'plans', icon: 'home' });
    links.push({ name: 'posts', icon: 'note-edit'})
    links.push({ name: 'privacies', icon: 'note-outline', icon_fixed: true });
    links.push({ name: 'terms', icon: 'note-outline', icon_fixed: true  });
    links.push({ name: 'laws', icon: 'note-outline', icon_fixed: true });
    links.push({ name: 'visa_select', icon: 'passport', icon_fixed: true });
    links.push({ name: 'visas', icon: 'history', icon_fixed: true });
    if (isLoggedIn) {
      //links.push({ name: 'recipients', icon: 'account-arrow-left' });
      /*links.push({ name: 'deposit_accounts', icon: 'bank' });*/
      //links.push({ name: 'orders', icon: 'file-clock' });
      links.push({ name: 'contact_messages', icon: 'email-edit', alt_names: ['contact_message','contact_message_thread'] });
      links.push({ name: 'profile', icon: 'account' });
      links.push({ name: 'license', icon: 'license', icon_fixed: true });
      links.push({ name: 'sessions', icon: 'shield-account' });
      links.push({ name: 'change_password', icon: 'lock' });
      links.push({ name: 'logout', icon: 'logout', icon_fixed: true });
    } else {
      links.push({ name: 'contact_message', icon: 'email-edit' });
    }
    //links.push({ name: 'anti_social', icon: 'shield-sword' });
    return links;
  }
}

export default new Settings();