<template>
  <div :class="classes">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <input
      type="password"
      :value="modelValue"
      class="input-text-a"
      @input="updateValue"
    >
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CInputPassword",
  props: {
    modelValue: String,
    label: String,
    classes: {
      type: String,
      default: 'spacer-t-a'
    },
    errors: Array
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const updateValue = (event) => {
        context.emit('update:modelValue', event.target.value);
    }

    return { updateValue }
  }
}
</script>