<template>
  <Tabs />
  <CMessage :messages="message.base" />

  <c-date-range
    v-model:from="filter.from"
    v-model:to="filter.to"
    :month-picker="true"
    :reload-fn="load"
    outer-class="card-a"
  />

  <c-line-chart
    :labels="inc_month_category_line.labels"
    :datasets="inc_month_category_line.datasets"
    :height="$screen.width < 640 ? 300 : ''"
  />

  <c-line-chart
    :labels="exp_month_category_line.labels"
    :datasets="exp_month_category_line.datasets"
    :height="$screen.width < 640 ? 300 : ''"
  />
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'
import Tabs from './Tabs'
export default {
  name: "BudgetSheetCheck",
  components: { Tabs },
  data: function () {
    return {
      message: {},
      filter: {
        type: 'cmcc',
        from: {
          year: new Date().getFullYear(),
          month: 0
        },
        to: {
          year: new Date().getFullYear(),
          month: 11
        }
      },
      evaluation_chart_data: {}
    }
  },
  computed: {
    inc_month_category_line() {
      if(this.evaluation_chart_data["inc"]) {
        return this.evaluation_chart_data["inc"].month_category_line
      }
      return {}
    },
    exp_month_category_line() {
      if(this.evaluation_chart_data["exp"]) {
        return this.evaluation_chart_data["exp"].month_category_line
      }
      return {}
    },
    filterParams() {
      let result = Object.assign({}, this.filter)
      if(this.filter.from && this.filter.from.year){
        result.from = new Date(this.filter.from.year, this.filter.from.month, 1)
      }
      if(this.filter.to && this.filter.to.year){
        result.to = new Date(this.filter.to.year, this.filter.to.month + 1, 0)
      }
      return result
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('current_user/budget_sheets/statistic', this.filterParams).then(
        response => {
          this.evaluation_chart_data = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>