import ClientService from '../common/ClientService';
const jwt = ClientService.getLocalJwt()
const initialState = jwt
  ? { isLoggedIn: true }
  : { isLoggedIn: false };
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, jwt) {
      ClientService.setLocalJwt(jwt);
      commit('login');
    },
    logout({ commit }) {
      ClientService.destroyLocalJwt();
      commit('logout');
    }
  },
  mutations: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    }
  }
};
