<template>
  <button
    :class="classes"
    :disabled="is_disabled"
    @click.prevent="after_click()"
  >
    <mdicon
      v-if="icon"
      :name="icon"
    />
    {{ translated? label : $t('views.action.' + label) }}
    <mdicon
      v-if="prependIcon"
      :name="prependIcon"
    />
  </button>
</template>

<script>
export default {
  name: "CButton",
  props: {
    label: String,
    icon: String,
    prependIcon: String,
    class: String,
    translated: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      internal_disabled: false
    }
  },
  computed: {
    classes() {
      return this.class || (this.is_disabled ? 'btn-a btn-disabled' : 'btn-a')
    },
    is_disabled() {
      return this.disabled || this.internal_disabled
    }
  },
  methods: {
    after_click() {
      this.internal_disabled = true;
      setTimeout(() => {
        this.internal_disabled = false;
      }, 1000);
    }
  }
}
</script>