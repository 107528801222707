<template>
  <div class="article">
    <CMessage :messages="message.base" />
    <h3>{{ anti_social.title }}</h3>
    <div v-html="anti_social.body" />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "AntiSocial",
  data: function () {
    return {
     message: {},
      anti_social: {
        title: '',
        body: ''
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('anti_social/articles/latest').then(
        response => {
          this.anti_social = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>