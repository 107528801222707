<template>
  <div :class="outerClass">
    <template v-if="!writting">
      <div
        class="flex cursor-pointer"
        @click.prevent="write()"
      >
        {{ fmtDate(from) }} - {{ fmtDate(to) }}
        <mdicon
          v-if="!writting"
          name="calendar-edit"
        />
      </div>
    </template>
    <div
      v-else
      :class="!isMobile ? 'flex' : ''"
    >
      <c-date-picker
        v-model="localFrom"
        :month-picker="monthPicker"
        :errors="errorsFrom"
      />
      <template v-if="!isMobile">
        〜
      </template>
      <c-date-picker
        v-model="localTo"
        :month-picker="monthPicker"
        :errors="errorsTo"
      />
    </div>
    <div v-if="writting">
      <c-button
        v-if="reloadFn"
        label="update"
        icon="reload"
        class="btn-a"
        @click.prevent="reload"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CDateRange",
  props: {
    from: [String, Date, Object],
    to: [String, Date, Object],
    reloadFn: [Function],
    errorsFrom: Array,
    errorsTo: Array,
    monthPicker: {
      type: Boolean,
      default: false
    },
    label: String,
    outerClass: {
      type: String,
      default: ''
    }
  },
  emits: ['update:from','update:to'],
  data() {
    return {
      writting: false
    }
  },
  computed: {
    isMobile() {
     return this.$screen.width < 640 
    },
    localFrom: {
      get() {
        return this.from
      },
      set(newValue) {
        this.$emit('update:from', newValue)
      }
    },
    localTo: {
      get() {
        return this.to
      },
      set(newValue) {
        this.$emit('update:to', newValue)
      }
    }
  },
  methods: {
    write() {
      this.writting = true
    },
    reload() {
      this.writting = false
      this.reloadFn()
    },
    fmtDate(dt) {
      if(!dt.year) {
        return dt
      }
      let year = dt.year
      let month = dt.month + 1
      switch (this.$i18n.locale) {
        case 'ja':
          return year + '/' + month
        default:
          return month + '/' + year
      }
    }
  },
}
</script>