<!-- https://stripe.com/docs/payments/accept-a-payment-charges -->
<template>
  <CMessage :messages="message.base" />
  <label for="card-element">
    {{ $t('activerecord.models.stripe_card') }}
  </label>
  <div v-if="!form_writting">
    <span>{{ stripe_card.overview }}</span>
    <div class="flex">
      <a class="link-a" @click.prevent="form_write()">{{ $t('views.action.change') }}</a>
      <a v-if="deletable" class="link-a ml-2 text-danger" @click.prevent="deleteCard()">{{ $t('views.action.destroy') }}</a>
    </div>
  </div>
  <form id="payment-form" v-show="form_writting">
    <div class="form-row">
      <div id="card-element">
        <!-- A Stripe Element will be inserted here. -->
      </div>

      <!-- Used to display Element errors. -->
      <div id="card-errors" role="alert"></div>
    </div>

    <CButton v-if="card_exist" label="close" @click.prevent="form_close()" class="btn-a-outline mr-2" />
    <input type="submit" :value="$t('views.action.save')" class="btn-a" />
  </form>
</template>
<script>
import ApiRequestor from '../common/ApiRequestor'

export default {
  name: "StripeCards",
  props: {
    deletable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      message: {},
      writting: false,
      stripe_card: {}
    }
  },
  computed: {
    stripe_pk() {
      if(process.env.APP_ENV == 'production') {
        return 'pk_live_51OB6VpLZcgLKyktli6PBwqkpMIs0paR5oUYhN94kmVuX6oYh9pycdO2cyJsgEorwvtlXh8W4LbyPItOYHonwL7dn00wtxxnD7Z'
      }
      return 'pk_test_51OB6VpLZcgLKyktlQTQXzNat1c3m4If7ubHIXwK918Zst10dkWJ46sQ6lmWZWxnESAfg0N3W2JOVjy3JmiY1ydOH00rS0NFD56'
    },
    form_writting: {
      get() {
        return this.writting || !this.card_exist
      },
      set(newValue) {
        this.writting = newValue
      }
    },
    card_exist() {
      return this.stripe_card && this.stripe_card.id
    }
  },
  created(){
    this.loadCard()
  },
  mounted(){
    this.loadPaymentForm()
  },
  methods: {
    loadPaymentForm() {
      // Set your publishable key: remember to change this to your live publishable key in production
      // See your keys here: https://dashboard.stripe.com/apikeys
      var stripe = Stripe(this.stripe_pk, {
        locale: this.$i18n.locale
      });
      var elements = stripe.elements();
      // Custom styling can be passed to options when creating an Element.
      const style = {
        base: {
          // Add your base input styles here. For example:
          fontSize: '16px',
          color: '#32325d',
        },
      };

      // Create an instance of the card Element.
      const card = elements.create('card', {style});

      // Add an instance of the card Element into the `card-element` <div>.
      card.mount('#card-element');
      var stripeTokenHandler = this.submitStripeToken
      // Create a token or display an error when the form is submitted.
      const form = document.getElementById('payment-form');
      form.addEventListener('submit', async (event) => {
        event.preventDefault();

        const {token, error} = await stripe.createToken(card);

        if (error) {
          // Inform the customer that there was an error.
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = error.message;
        } else {
          // Send the token to your server.
          stripeTokenHandler(token);
        }
      });
    },
    form_write() {
      this.form_writting = true
      this.loadPaymentForm()
    },
    form_close() {
      this.form_writting = false
    },
    loadCard() {
      ApiRequestor.get('current_user/stripe_card').then(
        response => {
          this.message = {}
          this.stripe_card = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    deleteCard() {
      ApiRequestor.delete('current_user/stripe_card').then(
        response => {
          this.message = {}
          this.stripe_card = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    submitStripeToken(token){
      let stripe_card_params = {
        stripe_card: {
          token_id: token.id,
          card_id: token.card.id,
          last4: token.card.last4,
          exp_month: token.card.exp_month,
          exp_year: token.card.exp_year
        }
      }
      ApiRequestor.post('current_user/stripe_card', stripe_card_params).then(
        response => {
          this.stripe_card = response.data
          this.message = {}
          this.form_close()
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>