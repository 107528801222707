<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a w-1/2"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <CSpinner v-if="loading" />
    <div
      v-else
      :class="label ? 'w-1/2' : ''"
    >
      {{ displayValue() }}
    </div>
  </div>
</template>

<script>
import CSpinner from '../components/CSpinner'
export default {
  name: "CText",
  components: {
    CSpinner
  },
  props: {
    label: String,
    modelValue: [Number, String],
    currency: String,
    outerClass: {
      type: String,
      default: 'flex spacer-t-a'
    },
    enum: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: false
    },
    number: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    displayValue() {
      if(this.modelValue == null) return '';
      var val = this.modelValue;
      if(this.enum) {
        val = this.$t('enum.' + this.label + '.' + val)
      }
      if(this.date) {
        val = new Date(this.modelValue).toLocaleDateString(this.$i18n.locale)
      }
      if (this.number || this.currency) {
        val = this.modelValue.toLocaleString('en-US')
        if (this.currency) {
          val = val + ' ' + this.$t('enum.recipient.currency.' + this.currency)
        }
      }
      return val
    }
  }
}
</script>