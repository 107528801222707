<template>
  <CMessage :messages="message.base" />
  <c-line-chart
    :labels="exchange_rates.start_datetimes"
    :dataset="exchange_rates.rates"
    dataset-label="JPY → VND"
  />
  <div class="card-a">
    <h3 class="font-bold">
      {{ $t('views.route.exchange_comparation') }}
    </h3>
    <CInputText
      v-model="send_amount"
      pre-icon="currency-jpy"
      label="order.send_amount"
      type="number"
      :maxlength="9"
      :size="9"
    />
    <table>
      <tr>
        <td />
        <td />
        <td class="text-center">
          {{ $t('activerecord.attributes.order.fee') }}
        </td>
        <td class="text-center">
          {{ $t('activerecord.attributes.order.rate') }}
        </td>
        <td class="text-center">
          {{ $t('activerecord.attributes.order.receive_amount') }}
        </td>
      </tr>
      <tr>
        <td />
        <td>
          <CInputText
            v-model="ttm.name"
            :disabled="true"
            :maxlength="9"
            :size="9"
            placeholder="exchange_rate.name"
          />
        </td>
        <td>
          <CInputText
            v-model="ttm.fee"
            type="number"
            :disabled="true"
            :maxlength="6"
            :size="6"
          />
        </td>
        <td>
          <CInputText
            v-model="ttm.rate"
            type="number"
            :disabled="true"
            :maxlength="7"
            :size="9"
          />
        </td>
        <td>
          <CText
            :model-value="receive_amount(ttm)"
            :number="true"
            outer-class="w-[12px]"
          />
        </td>
      </tr>
      <tr
        v-for="(merchant, index) in merchants"
        :key="index"
      >
        <td>
          <mdicon
            name="minus-circle"
            class="text-danger cursor-pointer"
            @click.prevent="remove_merchant_rate(index)"
          />
        </td>
        <td>
          <CInputText
            v-model="merchant.name"
            :maxlength="9"
            :size="9"
            placeholder="exchange_rate.name"
          />
        </td>
        <td>
          <CInputText
            v-model="merchant.fee"
            type="number"
            :maxlength="6"
            :size="6"
          />
        </td>
        <td>
          <CInputText
            v-model="merchant.rate"
            type="number"
            :maxlength="7"
            :size="9"
          />
        </td>
        <td :class="receive_amount_color(merchant)">
          <CText
            :model-value="receive_amount(merchant)"
            :number="true"
            outer-class="w-[12px]"
          />
        </td>
      </tr>
    </table>
    <mdicon
      v-if="merchants.length<10"
      name="plus-circle"
      class="text-primary cursor-pointer"
      @click.prevent="add_merchant_rate()"
    />
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
import ClientService from '../common/ClientService'
export default {
  name: "ExchangeRate",
  data: function () {
    return {
      message: {},
      exchange_rates: {
        start_datetimes: [],
        rates: []
      },
      send_amount: 100000,
      ttm: {
        name: 'TTM',
        fee: 0,
        rate: 0,
        receive_amount: 0
      },
      merchants: []
    }
  },
  computed: {
    last_rate() {
      return this.exchange_rates.rates[this.exchange_rates.rates.length - 1]
    },
    receive_min() {
      let receive_amounts = this.merchants.map( m => m.receive_amount )
      return Math.min.apply(Math, receive_amounts);
    },
    receive_max() {
      let receive_amounts = this.merchants.map( m => m.receive_amount )
      return Math.max.apply(Math, receive_amounts);
    },
    merchantsWatcher() {
      return JSON.stringify({send_amount: this.send_amount, merchants: this.merchants})
    }
  },
  watch: {
    merchantsWatcher() {
      ClientService.setERM({send_amount: this.send_amount, merchants: this.merchants})
    }
  },
  created() {
    this.load()
    if(ClientService.getERM()) {
      let erm = ClientService.getERM()
      this.send_amount = erm.send_amount
      this.merchants = erm.merchants
    }
  },
  methods: {
    load() {
      ApiRequestor.get('exchange_rates').then(
        response => {
          this.exchange_rates = response.data
          this.ttm.rate = this.last_rate
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    receive_amount(merchant) {
      if(!this.send_amount || !merchant.rate) {
        return
      }
      let send_amount = this.send_amount.toLocaleString('en-US').replaceAll(',','')
      let merchant_fee = merchant.fee.toLocaleString('en-US').replaceAll(',','')
      let merchant_rate = merchant.rate.toLocaleString('en-US').replaceAll(',','')
      let merchant_receive_amount = (send_amount - merchant_fee) * merchant_rate
      merchant.receive_amount = Math.ceil(merchant_receive_amount)
      return merchant.receive_amount
    },
    receive_amount_color(merchant) {
      if(this.merchants.length < 2) {
        return ''
      }
      switch (merchant.receive_amount) {
        case this.receive_max:
          return 'text-primary'
        case this.receive_min:
          return 'text-danger'
        default:
          return ''
      }
    },
    add_merchant_rate() {
      this.merchants.push({
        name: '',
        fee: 0,
        rate: this.last_rate - 1,
      })
    },
    remove_merchant_rate(index) {
      this.merchants.splice(index, 1)
    }
  }
}
</script>