<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <Multiselect
      v-model="localModelValue"
      :value="modelValue"
      :options="options"
      :can-clear="canClear"
      :can-deselect="canDeselect"
    >
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          <span :class="value.icon" /><span class="ml-2">{{ value.label }}</span>
        </div>
      </template>
      <template #option="{ option }">
        <span :class="option.icon" /><span class="ml-2">{{ option.label }}</span>
      </template>
    </Multiselect>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CSelect",
  props: {
    modelValue: String,
    options: Array,
    label: String,
    outerClass: {
      type: String,
      default: 'spacer-y-a'
    },
    canClear: {
      type: Boolean,
      default: true
    },
    canDeselect: Boolean,
    errors: Array
  },
  emits: ['update:modelValue'],
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    }
  },
  watch: {
    options(value, oldValue) {
      if(!this.options.find(item => item.value == this.modelValue && (this.options.size > 0))) {
        this.$emit('update:modelValue', this.options[0].value);
      }
    }
  }
}
</script>