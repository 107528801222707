<template>
  <div>
    <h3>{{ term.title }}</h3>
    {{ terms.body }}
  </div>
</template>

<script>
import ApiRequestor from '../common/ApiRequestor'
export default {
  name: "Terms",
  data: function () {
    return {
      message: "",
      terms: {
        title: '',
        body: ''
      }
    }
  },
  methods: {
    load() {
      ApiRequestor.get('guides/latest').then(
        (response) => {
          this.terms = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>