<template>
  <div
    style="border-top-color:transparent"
    class="w-8 h-8 border-4 border-slate-500 border-dotted rounded-full animate-spin"
  />
</template>

<script>

export default {
  name: "CSpinner"
}
</script>