<template>
  <div :class="outerClass">
    <label
      v-if="label"
      class="label-a"
    >{{ $t('activerecord.attributes.' + label) }}</label>
    <div class="grid grid-cols-3 sm:grid-cols-6">
      <div
        v-for="(item, index) in options"
        :key="index"
        :class="activeTagClass(index)"
        @click.prevent="toggle(index)"
      >
        {{ item.label }}
      </div>
    </div>
    <span
      v-if="errors"
      class="alert-a"
    >{{ errors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "CInputTag",
  props: {
    modelValue: String,
    options: Array,
    label: String,
    required: {
      type: Boolean,
      default: true
    },
    outerClass: {
      type: String,
      default: 'spacer-y-a'
    },
    errors: Array
  },
  emits: ['update:modelValue'],
  computed: {
    localModelValue: {
      get() {
          return this.modelValue
      },
      set(newValue) {
          this.$emit('update:modelValue', newValue)
      },
    }
  },
  methods: {
    toggle(index) {
      if(this.localModelValue == this.options[index].value) {
        if(!this.required) {
          this.localModelValue = null
        }
      } else {
        this.localModelValue =  this.options[index].value
      }
    },
    activeTagClass(index) {
      return this.localModelValue == this.options[index].value ? 'tag-item-active' : 'tag-item'
    }
  }
}
</script>