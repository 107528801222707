<template>
  <Bar
    v-if="datasets"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'CBarChart',
  components: { Bar },
  props: {
    labels: Array,
    datasets: Array
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets // [ { label: string, data: Array , backgroundColor: string } ]
      }
    },
    chartOptions() {
      return {
        responsive: true
      }
    }
  }
}
</script>